import Vue from 'vue'
import Router from 'vue-router'
import '@/style/global.css'
import { getToken } from '@/utils/auth'

Vue.use(Router)

const routes = [
    { 
      path: '/', 
      name: "index",
      component: () => import('../views/index.vue'),
      children:[
        {
          path: "/device",
          name: "device",
          component: () => import("../views/device/index.vue"),
        },
        {
          path: "/peer",
          name: "peer",
          component: () => import("../views/peer/index.vue"),
        },
      ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/index.vue')
    },
]

const router = new Router({
  routes // 使用ES6的对象属性简写
})

export default router

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      // 处理其他错误
      console.error(err)
    }
  })
}

router.beforeEach(async (to, from, next) => {
  console.log('hou route ==> ', to.path)
  if (getToken()) {
    next() // 已登录情况下继续导航
  } else {
    if (to.path === '/login') {
      next() // 如果目标路由是登录页面则继续导航
    } else {
      next({ name: 'Login' }) // 其他页面重定向到登录页面
    }
  }
})

