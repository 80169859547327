// 设置localStorage项
function setLocalStorageItem (name, value, days = 7) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    localStorage.setItem(name, JSON.stringify({ value, expires }))
  }
  
// 获取localStorage项
function getLocalStorageItem (name) {
    const item = localStorage.getItem(name)
    if (item) {
        const { value, expires } = JSON.parse(item)
        if (expires && Date.now() > new Date(expires).getTime()) {
        localStorage.removeItem(name)
        return null
        } else {
        setLocalStorageItem(name, value) // 续期
        }
        return value
    }
    return null
}

function setToken (token) {
    setLocalStorageItem('token', token)
}

function getToken () {
    return getLocalStorageItem('token')
}

function cleanToken () {
    setLocalStorageItem('token', '')
}

export { setToken, getToken, cleanToken }
  